import React from 'react';
import HomePageCards from '../homePageCards';
import {
  visualMonitoring,
  keywordMonitoring,
  htmlElementMonitoring,
  technologyMonitoring,
  contentMonitoring,
  sourceCodeMonitoring,
  availabilityMonitoring,
  aiMonitoring,
  whoisMonitoring,
  sitemapMonitoring,
  backlinkMonitoring,
  rssMonitoring,
  apiMonitoring,
} from '../../images/svg-icons';
import './styles.scss';

export const Tools = [
  {
    name: 'Visual monitoring',
    icon: visualMonitoring,
    description: 'Monitor web pages for any visual changes. Select a part of the screen or the whole page.',
    link: '/visual-monitoring-walkthrough',
  },
  {
    name: 'HTML element monitoring',
    icon: htmlElementMonitoring,
    description: 'Monitor specific HTML elements or the content of any field for changes.',
    link: '/html-element-monitoring-walkthrough',
  },
  {
    name: 'Keyword monitoring',
    icon: keywordMonitoring,
    description: 'Monitor web pages for the presence or absence of specific keywords.',
    link: '/keyword-monitoring-walkthrough',
  },
  {
    name: 'Technology monitoring',
    icon: technologyMonitoring,
    description: 'Monitor changes to the tech stack or 3rd party scripts used on the web page.',
    link: '/technology-monitoring-walkthrough',
  },
  {
    name: 'Source code monitoring',
    icon: sourceCodeMonitoring,
    description: 'Monitor web pages for any source code/HTML modifications.',
    link: '/html-monitoring-walkthrough',
  },
  {
    name: 'Content monitoring',
    icon: contentMonitoring,
    description: 'Monitor the visible content on a web page for any changes.',
    // link: '/content-monitoring-walkthrough',
  },
  // {
  //   name: 'Automatic AI monitoring',
  //   icon: aiMonitoring,
  //   description:
  //     'Hexowatch will look for any types of changes (visual, content, source code and technology monitoring).',
  //   link: '/automatic-ai-monitoring-walkthrough',
  // },
  {
    name: 'Availability monitoring',
    icon: availabilityMonitoring,
    description: 'Monitor any URL for downtime, and get alerts when a web page goes down.',
    link: '/availability-monitoring-walkthrough',
  },
  {
    name: 'Domain WHOIS monitoring',
    icon: whoisMonitoring,
    description: 'Monitor the ownership and administrative records of the domain names.',
    link: '/domain-whois-monitoring-walkthrough',
  },
  {
    name: 'Sitemap monitoring',
    icon: sitemapMonitoring,
    description: 'Monitor any website for new or deleted pages via their sitemap.',
  },
  {
    name: 'API monitoring',
    icon: apiMonitoring,
    description: 'Monitor HTTP post and API endpoints for response changes.',
  },
  {
    name: 'Backlink monitoring',
    icon: backlinkMonitoring,
    description:
      'Monitor your backlink partners and get alerts when links back to your website are removed or modified.',
  },
  {
    name: 'RSS feed monitoring',
    icon: rssMonitoring,
    description: 'Monitor RSS feed updates for changes.',
  },
];

const HomePageServices = () => (
  <section className="services light_grey_section_area">
    <div className="container">
      <HomePageCards cards={Tools} svg />
    </div>
  </section>
);

export default HomePageServices;
